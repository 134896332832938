<template lang="html">
  <!-- single item markup -->
  <div
    class="item item__collection"
    :class="{
      fullWidth: fullWidthCheck
    }"
  >
    <div class="item__collection__wrapper">
      <div class="item__collection__info">
        <h5 class="type">Collection</h5>
        <h2 class="title">
          {{ name }}
        </h2>
        <p class="description">
          {{ descriptionPlaceholder }}
        </p>
      </div>
      <div class="item__collection__image">
        <img
          :src="`${s3RootPath}${imageName}` | imageResizedPath('large')"
          class="item__cover"
          alt=""
        />
      </div>
    </div>
  </div>
  <!-- end single item markup -->
</template>

<script>
export default {
  name: "CollectionItem",
  props: {
    name: String,
    color: String,
    description: String,
    imageName: String,
    fullWidthCheck: Boolean
  },
  beforeMount() {
    this.s3RootPath = this.s3Path;
  },
  computed: {
    descriptionPlaceholder() {
      if (!this.description) {
        return "";
      } else {
        return this.description.slice(0, 200) + " ...";
      }
    }
  },

  data() {
    return {
      // fullWidth: Boolean
    };
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.item {
  &__cover {
    // width: 100%;
    // height: 100%;
  }

  &__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
  }

  &__collection {
    // width: 466px;
    // width: 46%;
    width: 48%;
    // height: auto;
    // margin-bottom: 16px;
    margin-bottom: 5%;
    @include respond-to(screen-md) {
      // width: 479px;
      width: 49%;
      // width: 466px;
      // width: 25.5%;
      height: 300px;
      margin-bottom: 2.5%;
    }
    @include respond-to(screen-xl) {
      width: calc(16% * 2);
    }

    &.fullWidth {
      width: 100%;
      margin-bottom: 30px;
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
      background: rgba(255, 255, 255, 0.7);
      @include fontfamily(GraCompact);
      @include respond-to(screen-md) {
        flex-direction: row;
        height: 100%;
      }
    }
    &__info {
      width: 100%;
      max-height: 85px;
      // padding-top: 15px;
      // padding-bottom: 10px;
      // padding-left: 15px;
      // padding-right: 15px;
      padding: 12px;
      order: 2;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      @include respond-to(screen-md) {
        width: 50%;
        height: 100%;
        max-height: unset;
        order: 1;
        padding-top: 15px;
        padding-bottom: 10px;
        padding-left: 15px;
        padding-right: 15px;
      }
      .type {
        font-size: 13px;
        margin-bottom: 5px;
        @include respond-to(screen-md) {
          margin-bottom: 10px;
          font-size: 14px;
        }
      }
      .title {
        font-weight: 300;
        font-size: 16px;
        line-height: 1;
        @include respond-to(screen-sm) {
          font-size: 16px;
        }
        @include respond-to(screen-md) {
          font-size: 24px;
          // font-size: 16px;
        }
        @include respond-to(screen-md) {
          font-size: 32px;
        }
      }
      .description {
        display: none;
        font-size: 16px;
        @include respond-to(screen-md) {
          line-height: 1.2;
          margin-top: auto;
          display: block;
        }
      }
    }
    &__image {
      order: 1;
      width: 100%;
      @include respond-to(screen-md) {
        width: 50%;
        order: 2;
      }
      img {
        // object-fit: cover;
        width: 100%;
        // max-height: 160px;
        object-fit: contain;
        @include respond-to(screen-md) {
          object-fit: cover;
          width: 100%;
          max-height: unset;
          height: 100%;
        }
      }
    }
  }
}
</style>
